<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("CASH_REGISTER_READINGS.NUMBER_Z") }}</dt>
          <dd class="col-sm-8">
            {{ cashRegisterReading.number_z }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">{{ $t("CASH_REGISTER_READINGS.STARTS_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.starts_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("CASH_REGISTER_READINGS.ENDS_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.ends_at) }}
          </dd>
        </dl>

        <dl class="row" v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)">
          <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
          <dd class="col-sm-8">
            <organization :organization="cashRegisterReading.organization" />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.created_at) }}
          </dd>
        </dl>
        <dl class="row" v-if="cashRegisterReading.status">
          <dt class="col-sm-4">{{ $t("COMMON.CASH_REGISTER_READING") }}</dt>
          <dd class="col-sm-8">
            <cash-register-reading-status-badge
              :cashRegisterReading="cashRegisterReading"
            />
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8">
            {{ $timeZoneDateFormat(cashRegisterReading.updated_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("CASH_REGISTER_READINGS.CASHIN") }}
          </dt>
          <dd class="col-sm-8">
            {{ this.$formatCurrency(cashRegisterReading.sale_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("CASH_REGISTER_READINGS.REGISTER_ERRORS") }}
          </dt>
          <dd class="col-sm-8">
            {{
              cashRegisterReading.cashRegisterReadingPaymentItem
                ? $formatCurrency(cashRegisterReading.register_errors)
                : $formatCurrency(0)
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("CASH_REGISTER_READINGS.CASHOUT") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(cashRegisterReading.expense_amount) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("CASH_REGISTER_READINGS.CASH_BALANCE") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(cashRegisterReading.total_amount) }}
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("CASH_REGISTER_READINGS.SUBMITION") }}
          </dt>
          <dd class="col-sm-8">
            {{ $formatCurrency(cashRegisterReading.external_invoice_amount) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import IconCheck from "@/components/IconCheck.vue";
import CashRegisterReadingStatusBadge from "./CashRegisterReadingStatusBadge.vue";

export default {
  name: "cash-register-reading-view-global",
  components: { IconCheck, CashRegisterReadingStatusBadge },

  props: ["cashRegisterReading"],

  computed: {},
  data() {
    return {};
  },

  created() {},

  methods: {
    cashRegisterReadingUpdated() {
      this.$emit("cashRegisterReadingUpdated", true);
    },
  },

  mounted() {},

  watch: {
    cashRegisterReading(cashRegisterReading) {},
  },
};
</script>

<style scoped>
.expense-list {
  list-style: none;
  padding-left: 1.5rem;
}

.expense-list li {
  position: relative;
  margin-bottom: 0.5rem;
}

.expense-list li::before {
  content: "•";
  position: absolute;
  left: -1rem;
  color: #000;
}
</style>

<template>
  <div class="container-fluid">

    <cash-register-reading-invoice-items
      v-if="cashRegisterReading.id"
      @onInvoiceChanged="invoiceUpdated"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading.id"
      :destinationWarehouseId="cashRegisterReading.cashRegister.warehouse?.id"
      :key="renderKey"
      :addLineText="
        $t(
          'CASH_REGISTER_READING_EXPENSE_ITEMS.ADD_CASH_REGISTER_READING_EXPENSE_ITEM'
        )
      "
    />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
import CashRegisterReadingInvoiceItems from "@/components/CashRegisterReadingInvoiceItem/CashRegisterReadingInvoiceItems.vue";

export default {
  name: "cash-register-reading-view-invoice-items",

  components: { LogListTable, CashRegisterReadingInvoiceItems },

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },
  },

  mixins: [],

  data() {
    return {
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    invoiceUpdated() {
     this.$emit('cashRegisterReadingUpdated')
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>

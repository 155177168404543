<template>
  <div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{ $t("COMMON.BILL_COUNT") }}</th>
          <th>{{ $t("COMMON.BILL_VALUE") }}</th>
          <th>{{ $t("COMMON.TOTAL") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.bill_five"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'bill_five',
                  cashRegisterReadingPaymentItem.bill_five
                )
              "
            />
          </td>
          <td>5</td>
          <td>{{ 5 * cashRegisterReadingPaymentItem.bill_five }}</td>
        </tr>

        <tr>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.bill_ten"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'bill_ten',
                  cashRegisterReadingPaymentItem.bill_ten
                )
              "
            />
          </td>
          <td>10</td>
          <td>{{ 10 * cashRegisterReadingPaymentItem.bill_ten }}</td>
        </tr>
        <tr>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.bill_twenty"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'bill_twenty',
                  cashRegisterReadingPaymentItem.bill_twenty
                )
              "
            />
          </td>
          <td>20</td>
          <td>{{ 20 * cashRegisterReadingPaymentItem.bill_twenty }}</td>
        </tr>
        <tr>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.bill_fifty"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'bill_fifty',
                  cashRegisterReadingPaymentItem.bill_fifty
                )
              "
            />
          </td>
          <td>50</td>
          <td>{{ 50 * cashRegisterReadingPaymentItem.bill_fifty }}</td>
        </tr>
        <tr>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.bill_hundred"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'bill_hundred',
                  cashRegisterReadingPaymentItem.bill_hundred
                )
              "
            />
          </td>
          <td>100</td>
          <td>{{ 100 * cashRegisterReadingPaymentItem.bill_hundred }}</td>
        </tr>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.COINS") }}</td>
          <td colspan="2">
            <div class="col">
              <base-input
                v-model="cashRegisterReadingPaymentItem.coins"
                @change="
                  updatePaymentItemDebounced(
                    'coins',
                    cashRegisterReadingPaymentItem.coins
                  )
                "
              />
              <validation-error :errors="apiValidationErrors.coins" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="col-12 text-center mb-2 mt-5">
      <h2>{{ $t("CASH_REGISTER_READINGS.PAYMENT_METHODS") }}</h2>
    </div>
    <table class="table table-bordered mt-4">
      <thead>
        <tr>
          <th>{{ $t("COMMON.PAYMENT_METHOD") }}</th>
          <th>{{ $t("COMMON.AMOUNT") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.CREDIT_CARD") }}</td>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.credit_card"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'credit_card',
                  cashRegisterReadingPaymentItem.credit_card
                )
              "
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.DEBIT_CARD") }}</td>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.debit_card"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'debit_card',
                  cashRegisterReadingPaymentItem.debit_card
                )
              "
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.PREPAIED_CARD") }}</td>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.prepaied_card"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced(
                  'prepaied_card',
                  cashRegisterReadingPaymentItem.prepaied_card
                )
              "
            />
          </td>
        </tr>

        <tr>
          <td>{{ $t("CASH_REGISTER_READINGS.OTHER") }}</td>
          <td>
            <base-input
              :disable="loading"
              v-model="cashRegisterReadingPaymentItem.other"
              input-classes="form-control-alternative"
              type="number"
              @change="
                updatePaymentItemDebounced('other', cashRegisterReadingPaymentItem.other)
              "
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";
import { cloneDeep } from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import TaxGroupsSelector from "../TaxGroupsSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";
export default {
  name: "cash-register-reading-payment-items",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    ValidationError,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin, formMixin],

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },

    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    const defaultCashRegisterReadingPaymentItem = {
      type: "cash-register-reading-payment-items",
      credit_card: 0,
      debit_card: 0,
      other: 0,
      coins: 0,
      prepaied_card: 0,
      bill_five: 0,
      bill_ten: 0,
      bill_twenty: 0,
      bill_fifty: 0,
      bill_hundred: 0,
      relationshipNames: ["cashRegisterReading"],
      cashRegisterReading: {
        type: "cash-register-readings",
        id: null,
      },
      allowedUsers: [],
    };

    let cashRegisterReadingPaymentItem = cloneDeep(defaultCashRegisterReadingPaymentItem);

    if (
      this.cashRegisterReading &&
      this.cashRegisterReading.cashRegisterReadingPaymentItem
    ) {
      cashRegisterReadingPaymentItem = cloneDeep(
        this.cashRegisterReading.cashRegisterReadingPaymentItem
      );
    } else if (this.cashRegisterReading) {
      cashRegisterReadingPaymentItem.cashRegisterReading.id = this.cashRegisterReading.id;
    }
    return {
      query: "",
      sort: "created_at",
      cashRegisterReadingPaymentItem,
      loading: true,
    };
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    user(user) {},
  },
  methods: {
    updatePaymentItemDebounced: _.debounce(function (fieldName, value) {
      this.updatePaymentItem(fieldName, value);
    }, 300),

    async updatePaymentItem(fieldName, value) {
      this.cashRegisterReadingPaymentItem[fieldName] = Number(value);
      this.loading = true;

      try {
        const cashRegisterReadingPaymentItemData = cloneDeep(
          this.cashRegisterReadingPaymentItem
        );

        if (cashRegisterReadingPaymentItemData.id) {
          await this.$store.dispatch(
            "cashRegisterReadingPaymentItems/update",
            cashRegisterReadingPaymentItemData
          );
        } else {
          delete cashRegisterReadingPaymentItemData.id;
          await this.$store.dispatch(
            "cashRegisterReadingPaymentItems/add",
            cashRegisterReadingPaymentItemData
          );
        }

        this.$notify({
          type: "success",
          message: this.$t("CASH_REGISTER_READINGS.CASH_REGISTER_READING_UPDATED"),
        });

        this.$emit("cashRegisterReadingUpdated");
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response?.data?.errors || {};
      } finally {
        this.loading = false;
      }
      this.$emit("onExpenseChanged");
    },
  },
};
</script>

<template>
  <div>
    <div>
      <div class="row">
        <div class="col-3">
          <base-input>
            <el-select
              class="select-primary pagination-select"
              v-model="pagination.perPage"
              :placeholder="$t('COMMON.PER_PAGE')"
            >
              <el-option
                class="select-primary"
                v-for="item in pagination.perPageOptions"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>

        <div class="col-3">
          <base-input
            v-model="query"
            type="search"
            prepend-icon="fas fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
        </div>
      </div>
      <h3>{{ $t('CASH_REGISTER_READINGS.TOTAL_SALES') }}: {{ this.$formatCurrency(totalAmount) }}</h3>
      <el-table
        class="table-responsive align-items-center table-flush supplier-product-list"
        header-row-class-name="thead-light"
        :data="products"
        type="expand"
        row-key="id"
      >
        <div slot="empty" v-if="loading">
          <img src="/img/loading.gif" style="height: 100px; width: 100px" />
        </div>
        <el-table-column type="expand">
          <template slot-scope="props">
            <form
              class=""
              @submit.prevent=""
              v-if="productsModel[props.row.id]"
            ></form>
          </template>
        </el-table-column>
        <el-table-column
          v-if="!filterProduct"
          :label="$t('COMMON.PRODUCT')"
          prop="product.name"
          min-width="400px"
        >
          <template v-slot="{ row }">
            <base-input v-if="row.id < 0">
              <product-selector
                :filterCanBeSold="true"
                :filterOrganization="filterOrganization"
                :product="row.product.id"
                :allowNone="false"
                :showAll="false"
                :disabled="row.id > 0"
                :filterWarehouse="
                  destinationWarehouseId ? destinationWarehouseId : null
                "
                @productChanged="
                  (productId, product) => {
                    productsModel[row.id].product.id = productId;
                    productsModel[row.id].sku = product.sku;
                    productsModel[row.id].selling_price = product.selling_price;
                    productsModel[row.id].buying_price = product.buying_price;
                    productsModel[row.id].taxGroups = product.taxGroups;
                    productsModel[row.id].product_name = product.name;
                    updateProductItemDebounced(row.id);
                  }
                "
              />
            </base-input>
            <object-link v-if="row.id > 0" :object="row" />
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('PRODUCTS.CUSTOM_SELLING_PRICE')"
          prop="unit_price"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :disabled="true"
              :placeholder="$t('PRODUCTS.CUSTOM_SELLING_PRICE')"
              v-model="productsModel[row.id].unit_price"
              input-classes="form-control-alternative"
              @change="updateProductItemDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('PRODUCTS.QUANTITY')"
          prop="quantity"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :placeholder="$t('PRODUCTS.QUANTITY')"
              v-model="productsModel[row.id].quantity"
              input-classes="form-control-alternative"
              @change="updateProductItemDebounced(row.id)"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('COMMON.TOTAL')"
          prop="total"
          min-width="250px"
        >
          <template v-slot="{ row }">
            <base-input
              :disabled="true"
              :placeholder="$t('PRODUCTS.QUANTITY')"
              v-model="row.total"
              input-classes="form-control-alternative"
            >
            </base-input>
          </template>
        </el-table-column>

        <el-table-column align="center" min-width="120px">
          <div slot-scope="{ row }" class="table-actions">
            <el-tooltip
              content="Loading"
              placement="top"
              v-if="productsModel[row.id].loading"
            >
              <a
                type="text"
                class="table-action table-action-delete"
                data-toggle="tooltip"
              >
                <i class="fas fa-spinner fa-spin"></i>
              </a>
            </el-tooltip>

            <el-tooltip
              content="Delete"
              placement="top"
              v-if="$currentUserCan($permissions.PERM_EDIT_PRODUCTS)"
            >
              <a
                type="text"
                @click="deleteProductItem(row)"
                class="table-action table-action-delete"
                data-toggle="tooltip"
                style="cursor: pointer"
              >
                <i class="fas fa-trash"></i>
              </a>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>

      <div class="row px-3 mt-3">
        <div v-if="loading" class="col-12 text-center">
          <i class="fas fa-spinner fa-spin fa-2x"></i>
        </div>
        <base-button
          v-if="!loading"
          type="info"
          class="btn-sm mr-1 col-12"
          style="width: 100%"
          @click="addLine"
        >
          <i class="fas fa-plus"></i>
          {{ addLineText }}
        </base-button>
      </div>
    </div>

    <div
      slot="footer"
      class="mt-5 col-12 d-flex justify-content-center justify-content-sm-between flex-wrap table-footer"
    >
      <div class="">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}

          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import SupplierSelector from "@/components/SupplierSelector.vue";
import ProductSelector from "@/components/ProductSelector.vue";
import { PRODUCT_STATUS, STATUS_ACTIVE } from "@/constants/products";
import TaxGroupsSelector from "../TaxGroupsSelector.vue";
import CategoryGroupSelector from "@/components/CategoryGroupSelector.vue";

export default {
  name: "cash-register-reading-items",

  components: {
    SupplierSelector,
    ProductSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    HtmlEditor,
    TaxGroupsSelector,
    CategoryGroupSelector,
  },

  mixins: [requestErrorMixin],

  props: {
    addLineText: {
      type: String,
      description: "Text for add line",
    },
    product: {
      type: Object,
      default: null,
      description: "Product",
    },
    cashRegisterReading: {
      type: String,
      default: null,
      description: "cash register reading",
    },
    destinationWarehouseId: {
      type: String,
      default: null,
      description: "warehouse id",
    },

    filterProduct: {
      type: String,
      default: null,
      description: "Product id",
    },
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
  },

  data() {
    return {
      query: "",
      selectedRows: [],
      sort: "created_at",
      total: 0,
      product_status: STATUS_ACTIVE,
      PRODUCT_STATUS,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      products: [],
      loading: true,
      selectedProduct: null,
      selectedCategory: null,
      productsModel: {},
      existingSuppliers: [],
      existingProducts: [],
    };
  },

  computed: {
    totalAmount() {
      return this.products.reduce((total, product) => {
        return total + (parseFloat(product.total) || 0);
      }, 0);
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    pagination: {
      handler: "getListDebounced",
      immediate: false,
      deep: true,
    },
    selectedProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    products: {
      handler: "refreshproducts",
      immediate: true,
    },
    query: {
      handler: "getListDebounced",
      immediate: false,
    },
    selectedSupplier: {
      handler: "getListDebounced",
      immediate: false,
    },
    filterProduct: {
      handler: "getListDebounced",
      immediate: true,
    },
    product_status: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedCategory: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    updateProductItemDebounced: _.debounce(function (id) {
      this.updateProductItems(id);
    }, 300),

    async getList() {
      this.loading = true;
      try {
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "cashRegisterReading,product",
        };

        if (this.selectedProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.selectedProduct,
            },
          };
        }

        if (!!this.cashRegisterReading) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              cashRegisterReading: this.cashRegisterReading,
            },
          };
        }
        if (this.filterProduct) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              product: this.filterProduct,
            },
          };
        }
        if (this.selectedCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              productInCategory: this.selectedCategory,
            },
          };
        }
        await this.$store.dispatch("cashRegisterReadingItems/list", params);
        this.products = this.$store.getters["cashRegisterReadingItems/list"];
        this.total = this.$store.getters["cashRegisterReadingItems/listTotal"];
        this.loading = false;
       
      } catch (error) {
        this.loading = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    refreshproducts() {
      const productsModelData = {};
      for (const item of this.products) {
        productsModelData[item.id] = _.cloneDeep(item);
        productsModelData[item.id].loading = false;
      }
      this.productsModel = productsModelData;
    },

    addLine() {
      let newLinesIds = Object.values(this.products)
        .filter((item) => item.id < 0)
        .map((item) => item.id);
      newLinesIds.push(-1);
      const newLineId = Math.min(...newLinesIds) - 1;
      const newCashRegisterReadingItem = {
        type: "cash-register-reading-items",
        id: newLineId,
        relationshipNames: ["product", "cashRegisterReading"],
        cashRegisterReading: {
          type: "cash-register-readings",
          id: this.cashRegisterReading,
        },
        product: {
          type: "products",
          id: this.product ? this.product.id : null,
        },
        loading: false,
      };
      this.products.push(_.cloneDeep(newCashRegisterReadingItem));
      this.productsModel[newLineId] = _.cloneDeep(newCashRegisterReadingItem);
    },

    async deleteProductItem(row) {
      if (row.id > 0) {
        const confirmation = await swal.fire({
          title: this.$t("PRODUCTS.DELETE_THIS_SUPPLIER_PRODUCT"),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t("COMMON.YES"),
          cancelButtonText: this.$t("COMMON.NO"),
          confirmButtonClass: "btn btn-primary",
          cancelButtonClass: "btn btn-warning",
        });
        if (confirmation.value !== true) {
          return;
        }
        await this.$store.dispatch("cashRegisterReadingItems/destroy", row.id);
      }
      this.getList();
    },

    async updateProductItems(id) {
      this.productsModel[id].loading = true;
      const productData = this.productsModel[id];

      if (productData.id > 0) {
        await this.$store.dispatch(
          "cashRegisterReadingItems/update",
          productData
        );
      } else {
        if (productData.product.id && this.cashRegisterReading) {
          delete productData.id;
          await this.$store.dispatch(
            "cashRegisterReadingItems/add",
            productData
          );
        }
      }
      this.getList();
      this.$emit('onSaleChanged')
    },

  
  },
};
</script>

<style>
.supplier-product-list .el-table__row .form-group {
  margin-bottom: 0;
}
</style>

<template>
  <div class="container-fluid">

    <cash-register-reading-expense-items
      v-if="cashRegisterReading.id"
      @onExpenseChanged="expenseUpdated"
      :filterOrganization="cashRegisterReading.organization?.id"
      :cashRegisterReading="cashRegisterReading.id"
      :destinationWarehouseId="cashRegisterReading.cashRegister.warehouse?.id"
      :key="renderKey"
      :addLineText="
        $t(
          'CASH_REGISTER_READING_EXPENSE_ITEMS.ADD_CASH_REGISTER_READING_EXPENSE_ITEM'
        )
      "
    />
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
import CashRegisterReadingExpenseItems from "@/components/CashRegisterReadingExpenseItem/CashRegisterReadingExpenseItems.vue";

export default {
  name: "cash-register-reading-view-expense-items",

  components: { LogListTable, CashRegisterReadingExpenseItems },

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },
  },

  mixins: [],

  data() {
    return {
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    expenseUpdated() {
     this.$emit('cashRegisterReadingUpdated')
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>

<template>
  <div>
  
    
  </div>
</template>
<script>
import LogListTable from "@/views/Pages/DefaultModule/LogManagement/partials/LogListTable.vue";
import CashRegisterReadingItems from "@/components/CashRegisterReadingItem/CashRegisterReadingItems.vue";

export default {
  name: "cash-register-reading-view-details",

  components: { LogListTable, CashRegisterReadingItems },

  props: {
    cashRegisterReading: {
      type: Object,
      default: null,
      description: "cash register reading",
    },
  },

  mixins: [],

  data() {
    return {
      renderKey: 0,
    };
  },

  computed: {},

  methods: {
    saleUpdated() {
     this.$emit('cashRegisterReadingUpdated')
    },
  },

  mounted() {},

  watch: {
    user(user) {},
  },
};
</script>
